import state from "./state";

export default {
  SET_INVOICE_DETAILS(state, payload) {
    state.registerInvoiceData = [{ ...payload }];
  },
  SET_UPDATE_PARTNER(state, payload) {
    state.dataForUpdatePartner = payload;
  },
};
