import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const getAllSpeaker = "getAllSpeakers";
const getSpeakerforAdmin = "getSpeakerforAdmin";
const updateSpeaker = "updateSpeaker";
const createSpeaker = "createSpeaker";

const createAgenda = "createAgendaImages";
const updateAgenda = "updateAgendaImages";
const getAgendaData = "getAllAgendaImages";
const deleteAgenda = "createSpeaker";

export default {
  getAllSpeaker(payload) {
    return Repository.post(transformRouteSpeaker(getAllSpeaker), payload);
  },
  getSpeakerforAdmin(payload) {
    return Repository.post(transformRouteSpeaker(getSpeakerforAdmin), payload);
  },
  updateSpeaker(payload) {
    return Repository.post(transformRouteSpeaker(updateSpeaker), payload);
  },
  createSpeaker(payload) {
    return Repository.post(transformRouteSpeaker(createSpeaker), payload);
  },

  //agenda
  createAgenda(payload) {
    return Repository.post(transformRouteAgenda(createAgenda), payload);
  },
  updateAgenda(payload) {
    return Repository.post(transformRouteAgenda(updateAgenda), payload);
  },
  getAgendaData(payload) {
    return Repository.post(transformRouteAgenda(getAgendaData), payload);
  },
  deleteAgenda(payload) {
    return Repository.post(transformRouteAgenda(deleteAgenda), payload);
  },
};

const transformRouteSpeaker = (route) => {
  return `/nhrdn/conference/${route}`;
};
const transformRouteAgenda = (route) => {
  return `/nhrdn/registration/${route}`;
};
