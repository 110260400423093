import RepositoryFactory from "@/apis";
const speakers = RepositoryFactory.speakers;
const user = RepositoryFactory.user;

export default {
  getAllSpeaker({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await speakers.getAllSpeaker(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSpeakerforAdmin({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await speakers.getSpeakerforAdmin(payload);

        resolve(data.data[0]);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateSpeaker({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await speakers.updateSpeaker(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  createSpeaker({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await speakers.createSpeaker(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  //agenda
  createAgenda({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await speakers.createAgenda(payload);

        resolve(data);
      } catch (error) {
        reject(error.response.data);
      }
    });
  },
  updateAgenda({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await speakers.updateAgenda(payload);

        resolve(data);
      } catch (error) {
        reject(error.response.data);
      }
    });
  },
  getAgendaData({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await speakers.getAgendaData(payload);

        resolve(data);
      } catch (error) {
        reject(error.response.data);
      }
    });
  },
  deleteAgenda({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await speakers.deleteAgenda(payload);

        resolve(data);
      } catch (error) {
        reject(error.response.data);
      }
    });
  },
};
